<script>
    import Vue from 'vue';
    import BaseVue from '@frontend/Base.vue';
    import Gen from '../helper/Gen';
    Vue.component("Header",()=>import("@frontend/part/Header2.vue"))

    export default {
        extends: BaseVue,
        data() {
            return {
            	mail:[],
                popular:[],
                page404:false,
            }
        },
        async mounted() {
        	await this.ready();
            Vue.component("Header",()=>import("@frontend/part/Header2.vue"))
            this.$set(this.$root, 'page', this);
            if (typeof SEMICOLON != "undefined") SEMICOLON.slider.init()
            setTimeout(() => {
                SEMICOLON.documentOnReady.init()
            }, 100)
            $(document).ready(() => {
                AOS.init()
            })
            this.apiGetDirectMailDetail()
        },
        methods: {
        	apiGetDirectMailDetail(){
        		Gen.apirest('/direct-mail/'+this.$route.params.slug, {}, (err, resp)=>{
	              	if(err) console.log(err)
                    if(resp.code==404)return this.page404=true
	              	this.mail=resp.data
                    this.popular=resp.popular
                    this.$root.topProgress.done()
	          	})
        	}
        },
        watch:{
        "$route.params.slug"(v){
            this.apiGetDirectMailDetail()
            },
        }
    };
</script>
<template>
	<div Content>
        <Component404 v-if="page404"></Component404>
        <div v-else>
    		<Header></Header>
            <section id="page-title" class="page-title-mini">
                <div class="container clearfix">
                    <h1 class="d-none d-md-block">&nbsp;</h1>
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><router-link :to="{name:'index'}">{{ web.mn_home }}</router-link></li>
                        <li class="breadcrumb-item"><router-link :to="{name:'DirectMail'}">{{ web.mn_dm }}</router-link></li>
                        <li class="breadcrumb-item active" aria-current="page">{{ mail.mdm_title }}</li>
                    </ol>
                </div>
            </section>
    		<section id="content" class="overflow-none">

    			<div class="content-wrap">
    				<section class="section nobottommargin notopmargin">
    					<div class="container">
    						<div class="row justify-content-center">
                                <div class="col-lg-8">
    								<div class="iframe_container">
    									<h2>{{ mail.mdm_title }}</h2>
    									<div class="wrap_author">
    										<ul>
    											<li><a href="javascript:;"><i class="icon-user21"></i> {{ mail.mdm_author }}</a></li>
    											<li><a href="javascript:;"><i class="icon-calendar21"></i> {{( mail.mdm_publish_date).dates('format') }}</a></li>
    										</ul>
    									</div>
    									<VImg :src="uploader(mail.mdm_file_detail)" :alt="mail.mdm_title" :title="mail.mdm_title"/></VImg>
    								</div>
                                    <div class="article_content_detail">
                                        <div v-html="mail.mdm_desc"></div>
                                    </div>
    							</div>
                                <div class="col-lg-4 side-art">
                                    <div class="wrap_side_article" style="width:100%;">
                                        <h3>{{ web.lbl_dm_lainya }}</h3>
                                        <div v-for="(v,k) in popular" class="wrap_article_side">
                                            <div class="row">
                                                <div class="col-md-4 col-5 pr-md-0 pr-lg-3">
                                                    <div class="pict_blog_side direct-mail">
                                                        <VImg class="img-responsive" :src="uploader(v.mdm_file,'89x118')" :alt="v.mdm_title"></VImg>
                                                    </div>
                                                </div>
                                                <div class="col-md-8 col-7">
                                                    <div class="wrap_article_side_content">
                                                        <h3>
                                                            <router-link :to="{name:'DirectMailDetail',params:{slug:v.mdm_slug}}">
                                                                {{ v.mdm_title }}
                                                            </router-link>
                                                        </h3>
                                                        <div class="wrap_author">
                                                            <ul>
                                                                <li>
                                                                    <router-link :to="{name:'DirectMailDetail',params:{slug:v.mdm_slug}}">
                                                                        <i class="icon-calendar21"></i>&nbsp;{{ (v.mdm_publish_date).dates('format') }}
                                                                    </router-link>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
    						</div>
    					</div>
    				</section>
    			</div>
    		</section>
        </div>
	</div>
</template>